function getCookie(cookieName) {
  try {
    if (!window || !window.top || !window.top.document || !window.top.document.cookie) {
      return null;
    }
  } catch (err) {
    console.warn("Could not access cookies on window:", err);
    return null;
  }

  const cookies = window.top.document.cookie.split(";");
  let cookieValue;

  cookies.forEach((cookie) => {
    const name = cookie.substring(0, cookie.indexOf("="));
    if (name.trim() === cookieName) {
      cookieValue = cookie.substring(cookie.indexOf("=") + 1);
    }
  });

  if (cookieValue) {
    return decodeURIComponent(cookieValue.trim());
  }
  return null;
}

export { getCookie };
