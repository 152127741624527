import BNAdElement from "./elements/b-a-d-element.js";
import getPublisherProvidedId from "./helpers/publisher-provided-id.js";
import { setupPageTargetings } from "./helpers/targeting.js";
import { activateDeveloperConsole } from "./helpers/developer-console.js";
import { getCookie } from "./helpers/cookie-helper.js";
import getContextConfig from "./helpers/get-context-config";
import plugins from "./plugins/index.js";

const config = getContextConfig();

async function setupAds() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has("bauConsole") || getCookie("bauConsole") !== null){
    activateDeveloperConsole();
  }

  window.googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(() => {
    getPublisherProvidedId((_, ppid) => {
      if (ppid) {
        googletag.pubads().setPublisherProvidedId(ppid);
      }
    });

    googletag.pubads().setPrivacySettings({
      nonPersonalizedAds: config.disablePersonalizedAds,
    });

    googletag.pubads().enableLazyLoad({
      fetchMarginPercent: 100,
      // Render ad slots when they are 30% from the viewable area.
      renderMarginPercent: 30,
      // Double the above values on mobile, where viewports are smaller
      // and users tend to scroll faster.
      // mobileScaling: 2.0,
    });

    googletag.pubads().collapseEmptyDivs(true);
    googletag.enableServices();
  });

  // third party plugins & google function calls here for a simpler design
  const managedPlugins = [...plugins]

  for (const plugin of managedPlugins) {
    googletag.cmd.push(() => plugin(googletag));
  }

  // Override these events to dispatch custom events for bad ad element
  const EVENT_TYPES = ["impressionViewable", "slotOnload", "slotRenderEnded", "slotRequested", "slotResponseReceived", "slotVisibilityChanged"];

  googletag.cmd.push(() => {
    for (const eventType of EVENT_TYPES) {
      googletag.pubads().addEventListener(eventType, (event) => {
        const target = document.getElementById(event.slot.getSlotElementId());
        if (target) {
          target.dispatchEvent(new CustomEvent("bad:" + eventType, { bubbles: true, detail: event }));
        }
      });
    }
  });

  // Setup bad targetings for the page
  setupPageTargetings();

  customElements.define("b-a-d", BNAdElement);
}

export { setupAds };
