import getContextConfig from "./get-context-config";

const config = getContextConfig();

const hostname = config.hostname;

import prebidUrlMap from "../static/prebid-url-map";

export default () => {
  const googleScript = document.createElement("script");
  const prebidScript = document.createElement("script");

  googleScript.type = "didomi/javascript"
  prebidScript.type = "didomi/javascript"

  googleScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
  prebidScript.src = prebidUrlMap[hostname] || prebidUrlMap[window.location.hostname] || "undefined";

  googleScript.dataset.vendor = "google";
  prebidScript.dataset.vendor = "iab:52";

  if (!document.querySelector("script[src^='securepubads.g.doubleclick.net/tag/js/gpt.js']")) {
    document.head.appendChild(googleScript);
  }

  if (!document.querySelector("script[src*='rubiconproject.com/prebid']") && prebidScript.src !== "undefined") {
    document.head.appendChild(prebidScript);
  }
};
