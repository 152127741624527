function getAdInfoCss() {
  return `
    .ad-info {
      pointer-events:none;
      padding-bottom:18px;
      position:relative;
    }

    .ad-info:after {
      line-height:1;
      content:"⋯";
      display:block;
      font-family:Helvetica,Arial,sans-serif;
      font-size:25px;
      cursor:pointer;
      color:#22222;
      pointer-events:all;
      position:absolute;
      right:4px;
      top:0;
    }

    .ad-info>div{
      pointer-events:all;
      display:inline-block;
    }`;
}

export default getAdInfoCss;
