function validateKeyValue(key, value) {
  if (!validateSchema[key].validate(value)) return false;

  return true;
}

const deviceTypes = ["desktop", "mobile", "tablet"];
const pageTypes = ["article", "section", "home", "layout", "playlist", "post", "webtv-article"];

const validateSchema = {
  device: {
    validate: (value) => {
      return typeof value === "string" && deviceTypes.includes(value) ? true : false;
    },
  },
  pageType: {
    validate: (value) => {
      return typeof value === "string" && pageTypes.includes(value) ? true : false;
    },
  },
  _ta_: {
    validate: (value) => {
      return typeof value === "string";
    },
  },
  abTest: {
    validate: (value) => {
      return Number.isInteger(Number(value)) ? true : false;
    },
  },
  tags: {
    validate: (value) => {
      return typeof value === "string" && value.split(",").every((it) => typeof it === "string") ? true : false;
    },
  },
  category: {
    validate: (value) => {
      return typeof value === "string" && value.split(",").every((it) => typeof it === "string") ? true : false;
    },
  },
  isLoggedIn: {
    validate: (value) => {
      return value.toLowerCase() === "true" || value.toLowerCase() === "false" ? true : false;
    },
  },
  isPayingCustomer: {
    validate: (value) => {
      return value.toLowerCase() === "true" || value.toLowerCase() === "false" ? true : false;
    },
  },
  accountLevel: {
    validate: (value) => {
      return typeof value === "string" && value === "Premium" ? true : false;
    },
  },
  theProgram: {
    validate: (value) => {
      return value.toLowerCase() === "true" || value.toLowerCase() === "false" ? true : false;
    },
  },
  ppid: {
    validate: (value) => {
      return typeof value === "string" ? true : false;
    },
  },
};
export default validateKeyValue;
