/**
 * Partitions an array into two, depending on the predicate. Values which
 * pass the predicate are returned as the first result, values which don't
 * are returned as the second.
 *
 * @template T
 * @param {[T]} array The array to iterate over
 * @param {(arg0: T) => boolean} predicate The function invoked per iteration
 * @returns {[[T], [T]]} Returns the array of grouped elements
 *
 * @example partition([4, 7, 1, 1], (num) => num > 3)
 * //=> [[4, 7], [1, 1]]
 */
export function partition(array, predicate) {
  return array.reduce(
    (result, value) => {
      result[predicate(value) ? 0 : 1].push(value);
      return result;
    },
    [[], []]
  );
}
