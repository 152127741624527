import { getCookie } from "./cookie-helper";
import getContextConfig from "./get-context-config";
import rollingHash from "@bonniernews/rolling-hash";

const config = getContextConfig();

async function hashFunction(str) {
  return window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(str)).then((arrayBuffer) => {
    return Array.from(new Uint8Array(arrayBuffer));
  });
}

function toHexFunction(hash) {
  return hash.map((b) => b.toString(16).padStart(2, "0")).join("");
}

function toBase64Function(hash) {
  const hashedString = hash.map((b) => String.fromCharCode(b)).join("");
  return window.btoa(hashedString);
}

function getPublisherProvidedId(callback) {
  if (!window.crypto) {
    return callback(new Error("No crypto"));
  }

  const id = getUserIdFromIdToken("idtoken") || getUserIdFromIdToken("bnidtoken") || getUserIdFromIdToken("bnidtoken-lab") || getUserIdFromLocalStorage() || getUserIdFromCookie() || config.ppid;

  if (!id) return callback(null, null);

  try {
    rollingHash(id, { hashFunction, toBase64Function, toHexFunction }).then((rolledHash) => {
      return callback(null, rolledHash);
    });
  } catch (e) {
    return callback(e);
  }
}

function isLoggedIn() {
  const id = getUserIdFromIdToken("idtoken") || getUserIdFromIdToken("bnidtoken") || getUserIdFromIdToken("bnidtoken-lab") || getUserIdFromLocalStorage() || getUserIdFromCookie();
  return id;
}

// Local has the common idtoken and it's called "bnidtoken" there
function getUserIdFromIdToken(name) {
  try {
    const idtoken = getCookie(name);

    if (!idtoken) return null;

    // idtoken is a JWT. While it'd be possible to verify the
    // payload with the public key, we are not that afraid of
    // tampering. We just need the ID.
    // eslint-disable-next-line no-unused-vars
    const [header, payload, signature] = idtoken.split(".");

    const userInfo = JSON.parse(atob(payload));

    return userInfo.user.id || userInfo.sub;
  } catch (e) {
    return null;
  }
}

// DN stores their user info in local storage
function getUserIdFromLocalStorage() {
  try {
    const userInfo = window.localStorage.getItem("userInfo");

    if (!userInfo) return null;

    return JSON.parse(decodeURIComponent(userInfo)).userId.split(".").pop();
  } catch (e) {
    return null;
  }
}

// Expressen Lifestyle stores their user info in a cookie
function getUserIdFromCookie() {
  const whoami = getCookie("whoami");

  try {
    if (!whoami) return null;

    return JSON.parse(atob(whoami)).id;
  } catch (e) {
    return null;
  }
}

export default getPublisherProvidedId;

export { isLoggedIn };
