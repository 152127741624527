function createAdLabel(fontSize, padding, bamAdSlotId, headerHeight) {
  const adLabel = document.createElement("div");
  adLabel.textContent = "ANNONS";
  adLabel.style.cssText = `color:white;background-color: hsla(0, 0%, 0%, 0.50);font-size: ${fontSize};position:fixed;top:${headerHeight};left:0px;font-weight: 700;padding:${padding};z-index: 1;`;

  document.getElementById(bamAdSlotId).appendChild(adLabel);
}

function createArrow(arrowWidth, arrowHeight, bamAdSlotId) {
  const arrow = document.createElement("div");
  arrow.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" width="${arrowWidth}" height="${arrowHeight}" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-down">
      <polyline points="7 13 12 18 17 13" />
      <polyline points="7 6 12 11 17 6" />
  </svg>`;
  arrow.style.cssText = `position: absolute; bottom: 0px; left: 0px; width: 100%;height: ${arrowHeight} !important; display: flex !important; align-items: center; justify-content: center; flex-direction: column; pointer-events: none;background-color: hsla(0, 0%, 0%, 0);`;
  document.getElementById(bamAdSlotId).appendChild(arrow);
}

function createCloseButton(closeButtonSize, bamAdSlotId) {
  const closeButton = document.createElement("div");
  closeButton.innerHTML = `<svg style="width: ${closeButtonSize};filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5));" viewBox="0 0 100 100">
      <path d="M0,0 L100,100 M0,100 L100,0" style="fill:none;stroke:white;stroke-width:15"></path>
  </svg>`;
  closeButton.style.cssText = `width: ${closeButtonSize};position: fixed;top: 25px;right: 10px`;
  closeButton.addEventListener("click", () => {
    /*
      There is a choice to be made if we decide on removing the google slot from the page or just scroll past.

      1. if we do remove it we should send out a event that the slot has been collapsed and let the site handle it.

      2. Scroll down to under it, this is the current implementation.
    */

    // Option 1.
    // googletag.destroySlots([googletag.pubads().getSlots().find((slot) => slot.getSlotElementId() === bamAdSlotId)]);
    // publishMessage("collapse", {
    //   slotName: bamAdSlotId.split("-")[0],
    //   slotNumber: bamAdSlotId.split("-")[1],
    //   id: bamAdSlotId,
    // });

    // Option 2.
    const contentStartHeight =
      window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom +
      1; //+ 1 to make sure the ad has been scrolled down all the way.

    window.scrollTo({ behavior: "smooth", top: contentStartHeight });
  });
  document.getElementById(bamAdSlotId).appendChild(closeButton);
}

function isAncestor(childWindow, frameWindow) {
  if (frameWindow === childWindow) {
    return true;
  } else if (childWindow === window.top) {
    return false;
  }
  return childWindow?.parent
    ? isAncestor(childWindow.parent, frameWindow)
    : null;
}

function getFullPageHeaderElement() {
  const selectors = [
    ".ad-fullpage__header",
    ".fullpage-ad__header",
    "#closePremiumAdWrapper",
    "#fullscreen-ad:first-child",
    "#rich-media-wrapper:first-child",
    "#ad-welcome:first-child",
  ];
  return selectors
    .map((selector) => document.querySelector(selector))
    .find((element) => element);
}

function getHeaderElement() {
  const selectors = [
    ".site-header__wrapper",
    ".site-header__row-1",
    "#navbar",
    ".sticky-header",
    ".sticky-menu",
    "#menu-sm",
    ".header-grid",
    ".site-header > div",
  ];
  return selectors
    .map((selector) => document.querySelector(selector))
    .find((element) => element);
}

function getBottomElement() {
  const selectors = [".sticky.bottom-0"];
  return selectors
    .map((selector) => document.querySelectorAll(selector)[0])
    .find((element) => element !== null);
}

function checkVisible(elm) {
  try {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  } catch (e) {
    return false;
  }
}

function getHeaderHeight() {
  const header = getHeaderElement();
  if (!header) return "0px";
  const headerHeight = header.offsetHeight;
  const afterStyle = getComputedStyle(header, "::after");
  const afterHeight = parseFloat(afterStyle.height) || 0;
  return `${headerHeight + afterHeight}px`;
}

function seenThisPlugin(event) {
  if (!event.data) return;

  let data, bamAdSlot, adIframe;

  try {
    data = JSON.parse(event.data);

    const frames = document.querySelectorAll('iframe[id*="google_ads_iframe"]');
    adIframe = Array.from(frames).find((frame) =>
      isAncestor(event.source, frame.contentWindow)
    );

    bamAdSlot = adIframe.closest("b-a-d");
  } catch (e) {
    return;
  }

  if (!adIframe || !bamAdSlot) return;

  if (data.action === "responsive") {
    const fullpageAdHeader = `${
      getFullPageHeaderElement() ? getFullPageHeaderElement().offsetHeight : 0
    }px`;
    const adIframeId = adIframe.id;
    const responsiveStyle = document.createElement("style");
    responsiveStyle.innerHTML = `
      [slotname='rich_media_premium'] [id*='${adIframeId}']{
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }
      [slotname='mob_rich_media_premium'] [id*='${adIframeId}']{
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }
      [slotname='rich_media_premium'], [slotname='mob_rich_media_premium']{
        height: calc(100% - ${fullpageAdHeader}) !important;}
    `;
    document.head.appendChild(responsiveStyle);
  } else if (data.highImpactRequest) {
    const bamAdSlotToHighImpactFormats = {
      mob: ["midscroll", "mobile_mega"],
      mob_rich_media_premium: ["topscroll", "topscroll_gaming"],
      rich_media_premium: ["topscroll", "topscroll_gaming"],
    };

    const adIframeId = adIframe.id;
    const bamAdSlotId = bamAdSlot.id;

    if (adIframeId === "" || bamAdSlotId === "") return;

    if (data.action === "highImpactSupportedFormat") {
      event.source.postMessage(
        JSON.stringify({
          action: bamAdSlotToHighImpactFormats[bamAdSlot.slotName],
        }),
        "*"
      );
    } else if (data.action === "midscroll") {
      const adWrapper = document.getElementById(bamAdSlotId);
      const boundingClientRect = adWrapper.parentElement.getBoundingClientRect();
      const wrapperLeftMargin = window.getComputedStyle(adWrapper).marginLeft;
      let leftMargin = boundingClientRect.left - parseInt(wrapperLeftMargin, 10);

      let headerHeight = getHeaderHeight();
      const midscrollStyle = document.createElement("style");

      if (!checkVisible(getHeaderElement())) {
        headerHeight = "0px";
      }

      if (window.location.host.includes("resume.se")) {
        midscrollStyle.textContent = `div:has(> #${bamAdSlotId}){transform: none !important;} div:has(> div:has(> #${bamAdSlotId})){margin: 0px -${leftMargin}px 0px -${leftMargin}px !important;}`;
        adWrapper.parentElement.parentElement.style.cssText = `margin: 0px -${leftMargin}px 0px -${leftMargin}px; !important`;
        leftMargin = 0;
      }
      if (window.location.host.includes("privataaffarer.se")) {
        adWrapper.parentElement.parentElement.style.cssText =
          "transform: none !important;";
        adWrapper.parentElement.parentElement.parentElement.style.cssText = `margin: 0px -${leftMargin}px 0px -${leftMargin}px; !important;width: 100vw !important;`;
        leftMargin = 0;
      }
      if (window.location.host.includes("hd.se")) {
        midscrollStyle.textContent = `.ad-text:has(+ #${bamAdSlotId}){display: none !important;}`;
        adWrapper.parentElement.style.cssText =
          "margin: 0px !important;transform: none !important;contain: none !important;";
      }

      const bottomElement = getBottomElement();
      const bottomHeight = bottomElement ? bottomElement.offsetHeight : 0;

      const labelHeight = `calc(100vh - ${headerHeight} - ${bottomHeight}px)`;

      // Uncomment this to find the header height instead.
      // const headerHeight = `${[".site-header__wrapper", ".site-header__row-1", "#navbar", ".sticky-header", ".sticky-menu", "#menu-sm", ".header-grid", ".site-header > div"].map((e => document.querySelector(e))).find((e => null !== e))?.offsetHeight || 0}px`;

      midscrollStyle.textContent += `[data-slotname='mob']:has(>#${bamAdSlotId}) { width: 100vw !important; margin-left: -${leftMargin}px;} div #${bamAdSlotId} { height: ${labelHeight} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${labelHeight}, 0px ${labelHeight})
        !important; margin: 0px 0px 0px 0px !important; padding: 0px !important; position: relative !important;width: 100vw !important;transform: none !important;}
        [id='${bamAdSlotId}'] [id*='${adIframeId}'], [id='${bamAdSlotId}'] [id*='${adIframeId}'] { height: calc(100vh - ${headerHeight} - ${bottomHeight}px) !important; width: 100vw
        !important;clip: rect(auto, auto, auto, auto) !important; position: fixed !important; left: 0px !important; top: ${headerHeight} !important;transform: none !important;}
        [id='${bamAdSlotId}'] [class*='ad-info_button'] {display: none !important;}`

      document.head.appendChild(midscrollStyle);

      if (bamAdSlotId.includes("mob"))
        createAdLabel("10px", "3px 8px", bamAdSlotId, headerHeight);
      else createAdLabel("14px", "5px 10px", bamAdSlotId, headerHeight);
    } else if (data.action === "topscroll_gaming") {
      const adWrapper = document.getElementById(bamAdSlotId);

      const topScrollStyle = document.createElement("style");
      const adSlotMinHeight = "calc(70vh)";

      if (window.location.host.includes("hd.se")) {
        topScrollStyle.textContent = `.ad-text:has(+ #${bamAdSlotId}){display: none !important;}`;
        adWrapper.parentElement.style.cssText = `margin: 0px !important;transform: none !important;contain: none !important;--ad-slot-minheight: ${adSlotMinHeight} !important;`;
      }

      topScrollStyle.textContent = `.ad-welcome, #premiumAdWrapper, #fullscreen-ad { height: ${adSlotMinHeight} !important; }
        .ad-take-over__header, .ad-welcome__header, .fullpage-ad__header, .ad-fullpage__header, #closePremiumAdWrapper, #fullscreen-ad > :first-child,
        #rich-media-wrapper > :first-child, #ad-welcome > :first-child { display: none !important;  visibility: hidden; height: 0;width: 0; overflow: hidden; padding: 0 !important; }
        div:has(> div#${bamAdSlotId}) { height: ${adSlotMinHeight} !important; }
        div #${bamAdSlotId}, #rich-media-wrapper { height: ${adSlotMinHeight} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${adSlotMinHeight}, 0px ${adSlotMinHeight}) !important;
        margin: 0px !important; padding: 0px !important; position: relative !important;--ad-slot-minheight: none;width: 100vw !important;}
        [slotname='rich_media_premium'] [id*='${adIframeId}'],
        [slotname='mob_rich_media_premium'] [id*='${adIframeId}'] { height: ${adSlotMinHeight} !important; width: 100vw !important; clip: rect(auto, auto, auto, auto) !important;
        position: fixed !important; left: 0px !important; transform: none !important;}
        @keyframes moveAndFade{0%{transform:translateY(0);opacity:1}50%{transform:translateY(50%);opacity:0}100%{transform:translateY(100%);opacity:0}}.feather.feather-chevrons-down polyline{animation:2s linear infinite moveAndFade}`

      document.head.appendChild(topScrollStyle);

      if (bamAdSlotId.includes("mob")) {
        createAdLabel("10px", "3px 8px", bamAdSlotId);
        createArrow("50px", "70px", bamAdSlotId);
        createCloseButton("20px", bamAdSlotId);
      } else {
        createAdLabel("14px", "5px 10px", bamAdSlotId);
        createArrow("70px", "90px", bamAdSlotId);
        createCloseButton("30px", bamAdSlotId);
      }
    } else if (data.action === "topscroll") {
      const adWrapper = document.getElementById(bamAdSlotId);

      const bannedHeight = "calc(100vh - 80px)";

      const topScrollStyle = document.createElement("style");

      if (window.location.host.includes("hd.se")) {
        topScrollStyle.textContent = `.ad-text:has(+ #${bamAdSlotId}){display: none !important;}`;
        adWrapper.parentElement.style.cssText = `margin: 0px !important;transform: none !important;contain: none !important;--ad-slot-minheight: ${bannedHeight} !important;`;
      }

      topScrollStyle.textContent = `.ad-welcome, #premiumAdWrapper, #fullscreen-ad { height: ${bannedHeight} !important; }
        .ad-take-over__header, .ad-welcome__header, .fullpage-ad__header, .ad-fullpage__header, #closePremiumAdWrapper, #fullscreen-ad > :first-child,
        #rich-media-wrapper > :first-child, #ad-welcome > :first-child { display: none !important;  visibility: hidden; height: 0;width: 0; overflow: hidden; padding: 0 !important; }
        div:has(> div#${bamAdSlotId}) { height: ${bannedHeight} !important; }
        div #${bamAdSlotId}, #rich-media-wrapper { height: ${bannedHeight} !important; clip-path: polygon(0px 0px, 100vw 0px, 100vw ${bannedHeight}, 0px ${bannedHeight}) !important;
        margin: 0px !important; padding: 0px !important; position: relative !important;--ad-slot-minheight: none;width: 100vw !important;}
        [slotname='rich_media_premium'] [id*='${adIframeId}'],
        [slotname='mob_rich_media_premium'] [id*='${adIframeId}'] { height: ${bannedHeight} !important; width: 100vw !important; clip: rect(auto, auto, auto, auto) !important;
        position: fixed !important; left: 0px !important; transform: none !important;}
        @keyframes moveAndFade{0%{transform:translateY(0);opacity:1}50%{transform:translateY(50%);opacity:0}100%{transform:translateY(100%);opacity:0}}.feather.feather-chevrons-down polyline{animation:2s linear infinite moveAndFade}`

      document.head.appendChild(topScrollStyle);

      if (bamAdSlotId.includes("mob")) {
        createAdLabel("10px", "3px 8px", bamAdSlotId);
        createArrow("50px", "70px", bamAdSlotId);
        createCloseButton("20px", bamAdSlotId);
      } else {
        createAdLabel("14px", "5px 10px", bamAdSlotId);
        createArrow("70px", "90px", bamAdSlotId);
        createCloseButton("30px", bamAdSlotId);
      }
    } else if (data.action === "mobile_mega") {
      const adWrapper = document.getElementById(bamAdSlotId);

      const boundingClientRect = adWrapper.parentElement.getBoundingClientRect();
      const wrapperLeftMargin = window.getComputedStyle(adWrapper).marginLeft;
      let leftMargin = boundingClientRect.left - parseInt(wrapperLeftMargin, 10);

      const mobileMegaStyle = document.createElement("style");

      mobileMegaStyle.textContent = `
        [data-slotname='mob']:has(>#${bamAdSlotId}) { width: 100vw !important; margin-left: -${leftMargin}px;}
        div:has(> [id='${adIframeId}']) {width: 100% !important; height: 568px !important;}
        [id='${adIframeId}'] {width: 100% !important; height: 568px !important;}
        #${bamAdSlotId} { width: 100vw !important;}
      `;

      document.head.appendChild(mobileMegaStyle);
  }
  }
}

export default () => {
  window.addEventListener("message", (event) => {
    seenThisPlugin(event);
  });
};

export {
  createAdLabel,
  createArrow,
  createCloseButton,
  isAncestor,
  getFullPageHeaderElement,
  getHeaderElement,
  getHeaderHeight,
  getBottomElement,
  checkVisible,
  seenThisPlugin,
};
