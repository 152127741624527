const DEMAND_MANAGER_URL = 'https://ads.rubiconproject.com/prebid';
const DYNAMIC_DEMAND_MANAGER_URL = 'https://micro.rubiconproject.com/prebid/dynamic/12954.js';

export default {
  "localhost": `${DEMAND_MANAGER_URL}/12954_Expressen.js`, // local testing
  "127.0.0.1": `${DEMAND_MANAGER_URL}/12954_Expressen.js`, // local testing
  "assets.bonad.io": `${DEMAND_MANAGER_URL}/12954_Expressen.js`,
  "viivilla.se": DYNAMIC_DEMAND_MANAGER_URL,
  "lab.viivilla.se": DYNAMIC_DEMAND_MANAGER_URL,
  "expressen.se": `${DEMAND_MANAGER_URL}/12954_Expressen.js`,
  "www-livedata.expressen.se": `${DEMAND_MANAGER_URL}/12954_Expressen.js`,
}