import BNAdElement from "./elements/b-a-d-no-consent-element";
import { getCookie } from "./helpers/cookie-helper";
import { activateDeveloperConsole } from "./helpers/developer-console";

async function setupNoConsentAds() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has("bauConsole") || getCookie("bauConsole") !== null){
    activateDeveloperConsole();
  }

  customElements.define("b-a-d", BNAdElement);
}

export { setupNoConsentAds };
