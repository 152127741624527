import getContextConfig from "./get-context-config";

const config = getContextConfig();

// Handpicked variables from config
const device = config.device;
const hostname = config.hostname;

export default async () => {
  try {
    if (!window.bamData) {
      const queryParams = new URLSearchParams({ url: hostname, device });
      if (["undefined", "unknown"].includes(queryParams.get("device"))) queryParams.delete("device")
      window.bamData = await (await fetch(`https://bam.bonad.io/?${queryParams}`)).json();
    }
  } catch (error) {
    console.log("setup error: ", error);
    return Error("Could not fetch bam data");
  }
}
