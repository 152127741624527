function addOnUrlUpdateToPushState() {
  const oldPushState = history.pushState;
  history.pushState = function pushState() {
    let ret = oldPushState.apply(this, arguments);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  };

  const oldReplaceState = history.replaceState;
  history.replaceState = function replaceState() {
    let ret = oldReplaceState.apply(this, arguments);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  };

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'));
  });
}

function onUrlUpdate(cb) {
  // If Navigation API exist, use it, otherwise update the pushState API and listen to locationchange event
  if (window.navigation) return navigation.addEventListener("navigate", cb);

  addOnUrlUpdateToPushState();

  window.addEventListener("locationchange", cb);
}

export { onUrlUpdate };